import { url } from "../url/url";
export const Title = `Show an order`;
export const Requires = `requires authentication`;
export const Content = `show an order informations`;
export const Method = "GET";
export const Url = `api/order/{tracking}`;
export const Bash =
    `url  --request GET 
          --get "${url}/api/order/doloribus" 
          --header "Api-Key: {YOUR_AUTH_KEY}" 
          --header "Content-Type: application/json" 
          --header "Accept: application/json"      `;

export const JavaScript = `
const url = new URL(
    "${url}/api/order/doloribus"
);

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `
{
    "id": 1107985,
    "tracking": "DOT-djdhhA",
    "name": "name",
    "lastname": "last name",
    "adress": "the addresse of the client",
    "city": 253,
    "amount": 0,
    "free_shipping": "No",
    "phone": "0634073697",
    "hub": 1,
    "shipper": 1158,
    "created_at": "2022-01-04T15:11:27.000000Z",
    "updated_at": "2022-01-04T15:11:27.000000Z",
    "status": 4,
    "article": "extra information about the package \n",
    "size": "small",
    "delivery_type": "Forward",
    "code_rtc": "",
    "stock": "None",
    "attempts_count": 1,
    "aging": 0,
    "order_city": "Salé",
    "order_status":"En-livraison",
    "order_region": "Tanger-Tétouan-Al Hoceïma",
    "order_company": "your shop name",
    "first_mile": "Salé",
    "last_mile": "Salé"
    "runsheet_package": {
        "id": 148596,
        "action_date": null,
        "rescheduled": null,
        "status": "En-livraison",
        "sub_status": []
    }
}
`
export const URLParameters = [{ 'tracking': 'string' }];
export const CSharp = `
var client = new HttpClient();
        client.BaseAddress = new Uri("${url}");
        client.DefaultRequestHeaders.Accept.Clear();
        client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
        client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
        client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = await client.GetAsync("/api/order/doloribus");
return response;
`
export const java = `
URL url = new URL("${url}/api/order/doloribus");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
    con.setRequestMethod("GET");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
return con.getResponseMessage();
`