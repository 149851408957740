import { url } from "../url/url";
export const Title = `Order timeline`;
export const Requires = `requires authentication`;
export const Content = `Get an order life cycle`;
export const Method = "GET";
export const Url = `api/orders/add`;
export const Bash =
    `curl --request GET 
    --get "${url}/api/order/eaque/timeline" 
    --header "Api-Key: {YOUR_AUTH_KEY}" 
    --header "Content-Type: application/json" 
    --header "Accept: application/json"`;

export const JavaScript = `
const url = new URL(
    "${url}/api/order/eaque/timeline"
);

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `
[
    {
        "order_id": 135662,
        "created_at": "2022-01-28 16:13:30",
        "status": "En-attente",
        "station": "Salé-Station"
    },
    {
        "order_id": 135662,
        "created_at": "2022-01-28 17:18:21",
        "status": "En-ramassage",
        "station": "Salé-Station"
    },
    {
        "order_id": 135662,
        "created_at": "2022-01-28 17:18:52",
        "status": "À-l'entrepôt",
        "station": "Salé-Station"
    },
    {
        "order_id": 135662,
        "created_at": "2022-01-29 05:51:47",
        "status": "En-livraison",
        "station": "Salé-Station"
    },
    {
        "order_id": 135662,
        "created_at": "2022-01-29 17:01:13",
        "status": "Livré",
        "station": "Salé-Station"
    },
    {
        "order_id": 135662,
        "created_at": "2022-01-31 12:58:43",
        "status": "Terminé",
        "station": "Salé-Station"
    }
]
`
export const URLParameters = [{ 'tracking': 'string' }];
export const CSharp = `
var client = new HttpClient();
        client.BaseAddress = new Uri("${url}");
        client.DefaultRequestHeaders.Accept.Clear();
        client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
        client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
        client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = await client.GetAsync("/api/order/eaque/timeline");
return response;
`;
export const java = `
URL url = new URL("${url}/api/order/eaque/timeline");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
    con.setRequestMethod("GET");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
return con.getResponseMessage();
    `;