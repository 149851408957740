import { url } from "../url/url";
export const Title = `Delivery - Delivery performance`;
export const Requires = `requires authentication`;
export const Content = `Get the delivered and canceled orders stats by days of the current week or months of the current year.`;
export const Method = "GET";
export const Url = `api/delivery/performance`;
export const Bash =
    `curl --request GET 
          --get "${url}/api/delivery/performance?user=94&period=monthly" 
          --header "Api-Key: {YOUR_AUTH_KEY}" 
          --header "Content-Type: application/json" 
          --header "Accept: application/json" 
 `;

export const JavaScript = `
const url = new URL(
    "${url}/api/delivery/performance?user=94&period=monthly"
);
const params = {
    "user": "94",
    "period": "monthly"
};
Object.keys(params)
    .forEach(key => url.searchParams.append(key, params[key]));
const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};
fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `
{
    "values": [
        {
            "description": "En-attente",
            "count": 9
        }
    ],
    "perf": {
        "delivery": [
            {
                "packages": 0,
                "day": "January"
            },
            {
                "packages": 0,
                "day": "February"
            },
            {
                "packages": 0,
                "day": "March"
            },
            {
                "packages": 0,
                "day": "April"
            },
            {
                "packages": 0,
                "day": "May"
            },
            {
                "packages": 0,
                "day": "June"
            },
            {
                "packages": 0,
                "day": "July"
            },
            {
                "packages": 0,
                "day": "August"
            },
            {
                "packages": 0,
                "day": "September"
            },
            {
                "packages": 0,
                "day": "October"
            },
            {
                "packages": 0,
                "day": "November"
            },
            {
                "packages": 0,
                "day": "December"
            }
        ],
        "canceled": [
            {
                "packages": 0,
                "day": "January"
            },
            {
                "packages": 0,
                "day": "February"
            },
            {
                "packages": 0,
                "day": "March"
            },
            {
                "packages": 0,
                "day": "April"
            },
            {
                "packages": 0,
                "day": "May"
            },
            {
                "packages": 0,
                "day": "June"
            },
            {
                "packages": 0,
                "day": "July"
            },
            {
                "packages": 0,
                "day": "August"
            },
            {
                "packages": 0,
                "day": "September"
            },
            {
                "packages": 0,
                "day": "October"
            },
            {
                "packages": 0,
                "day": "November"
            },
            {
                "packages": 0,
                "day": "December"
            }
        ]
    }
}
`
export const QueryParameters = [
    {
        "id": 0,
        "name": "user",
        "ParamType": "string",
        "Description": "id of the user to get it's statistics.",
        "Required": "Yes",

    }
    ,
    {
        "id": 0,
        "name": "period",
        "ParamType": "string",
        "Description": "'daily' or 'monthly'.",
        "Required": "Yes",
    }

];
export const CSharp = `
var client = new HttpClient();
    client.BaseAddress = new Uri("${url}");
    client.DefaultRequestHeaders.Accept.Clear();
    client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
    client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
    client.DefaultRequestHeaders.Add("Content-Type", "application/json");
Dictionary<string, string> params = new Dictionary<string, string>();
    params.Add("user","93");
    params.Add("period","monthly");
string url="/api/delivery/performance?";
foreach(var item in params)
    {    url+=item.Key+"="+item.Value+"&";    }  
    HttpResponseMessage response = await client.GetAsync(url);
    return response;
}
`
export const java = `
URL url = new URL("${url}/api/delivery/performance?");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
con.setRequestMethod("POST");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
    con.setDoOutput(true);
Map<String, String> parameters = new HashMap<>();
    parameters.put("user" , "94"); 
    parameters.put("period" ,"monthly"); 
StringBuilder result = new StringBuilder();
for (Map.Entry<String, String> entry : parameters.entrySet()) {
          result.append(URLEncoder.encode(entry.getKey(), "UTF-8"));
          result.append("=");
          result.append(URLEncoder.encode(entry.getValue(), "UTF-8"));
          result.append("&");
    }    
String resultString = result.toString();
    resultString = resultString.length() > 0
          ? resultString.substring(0, resultString.length() - 1)
          : resultString;
DataOutputStream out = new DataOutputStream(con.getOutputStream());
    out.writeBytes(resultString);
    out.flush();
    out.close();      
return con.getResponseMessage();`