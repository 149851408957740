import React, { Fragment, useEffect, useState } from "react";
import ChildRoute from "../routes/ChildRoute";

const MultipleRoutes = ({ route, ToggleLi, ToggleUl, isSubChild }) => {
  const [GetClass, SetClass] = useState("mm-show");
  const [GetActive, SetActive] = useState("mm-active");
  const [GETsubChild, SETsubChild] = useState("");
  useEffect(() => {
    SetClass(ToggleLi);
    SetActive(ToggleUl);
    isSubChild && SETsubChild(" ml-3");
  }, [ToggleLi, ToggleUl, isSubChild])

  const toggle = (e) => {
    e.preventDefault();
    if (GetClass.length > 0 && GetActive.length > 0) {
      SetClass("");
      SetActive("");
    } else {
      SetClass("mm-show");
      SetActive("mm-active")
    }
  }

  return (
    <>
      {typeof route !== "undefined" &&
        Object.keys(route).length !== 0 && (
          <li id="MyLI" className={GetActive + GETsubChild}>
            <a
              href="javascript:;"
              rel=""
              aria-expanded="false"
              className="has-arrow"
              onClick={toggle}
            >
              <div className="parent-icon">
                <i className={Object.values(route)[1].class}></i>
              </div>
              <div className="menu-title">{Object.values(route)[1].name}</div>
            </a>

            <ul className={"mm-collase mm-collapse " + GetClass}>
              {Object.entries(Object.values(route)[2]).map((mychild, id) => {
                if (Object.keys(mychild[1])[2] !== "subchild") {
                  return (
                    <Fragment key={id}>
                      <ChildRoute childroute={Object.values(mychild)} />
                    </Fragment>
                  );
                } else {
                  return (
                    <Fragment key={id} >
                      <MultipleRoutes route={mychild[1]}
                        ToggleLi={ToggleLi}
                        ToggleUl={ToggleUl}
                        isSubChild={true}
                      />
                    </Fragment>
                  )
                }
              })}
            </ul>
          </li>
        )
      }
    </>
  );
};

export default MultipleRoutes;



// return (
//   <Fragment key={id}>
//     <ChildRoute childroute={Object.values(mychild)} />
//   </Fragment>
// );