import { url } from "../url/url";
export const Title = `Order Label`;
export const Requires = `requires authentication`;
export const Content = `A pdf tikcet inclusing the information of the order`;
export const Method = "GET";
export const Url = `api/order/{tracking}/generate-label`;
export const Bash =
    `curl --request GET 
    --get "${url}/api/order/minus/generate-label" 
    --header "Api-Key: {YOUR_AUTH_KEY}" 
    --header "Content-Type: application/pdf" 
    --header "Accept: application/json"     `;

export const JavaScript = `const url = new URL(
    "${url}/api/order/minus/generate-label"
);
const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/pdf",
    "Accept": "application/json",
};
fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const URLParameters = [{ 'tracking': 'string' }];

export const CSharp = `
var client = new HttpClient();
        client.BaseAddress = new Uri("${url}");
        client.DefaultRequestHeaders.Accept.Clear();
        client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
        client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
        client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = await client.GetAsync("/api/order/minus/generate-label");
return response;
`
export const java = `
URL url = new URL("${url}/api/order/minus/generate-label");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
    con.setRequestMethod("GET");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
return con.getResponseMessage();
`;
