import { url } from "../url/url";
export const Title = `Stations`;
export const Requires = `requires authentication`;
export const Content = `Get the list of our stations/warehouses`;
export const Method = `GET`;
export const Url = `api/essentials/stations`;
export const Bash =
    `curl --request GET 
          --get "${url}/api/essentials/stations" 
          --header "Api-Key: {YOUR_AUTH_KEY}" 
          --header "Content-Type: application/json" 
          --header "Accept: application/json"  `;
export const JavaScript = `const url = new URL(
    "${url}/api/essentials/stations"
);

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `
[
    {
        "id": 1,
        "text": "Salé-Station"
    },
    {
        "id": 6,
        "text": "Tanger-Station"
    },
    {
        "id": 7,
        "text": "Meknès-Station"
    },
    {
        "id": 8,
        "text": "Casablanca-Station"
    },
    {
        "id": 10,
        "text": "Marrakech-Station"
    },
    {
        "id": 11,
        "text": "Agadir-Station"
    },
    {
        "id": 12,
        "text": "Oujda-Station"
    }
]
`
export const CSharp = `
var client = new HttpClient();
        client.BaseAddress = new Uri("${url}");
        client.DefaultRequestHeaders.Accept.Clear();
        client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
        client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
        client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = await client.GetAsync("/api/essentials/stations");
return response;
`;
export const java = `
URL url = new URL("${url}/api/essentials/stations");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
    con.setRequestMethod("GET");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
return con.getResponseMessage();`