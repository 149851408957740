import React from 'react'
import { Link } from 'react-router-dom';
const ChildRoute = ({ childroute }) => {
    return (
        <li>
            <Link to={Object.values(childroute[1])[1]} className='d-flex justify-content-between'>
                {Object.values(childroute[1])[0]}
                <span className={`text-xs p-1 border-2 text-white border-white font-bold rounded `}>{Object.values(childroute[1])[2]}</span>
            </Link>

        </li>
    )
}

export default ChildRoute