import React from "react";
import { Link } from "react-router-dom";

const SingleRoute = ({ route }) => {
    return (<>
        {Object.keys(route).length !== 0 &&
            <li>
                <Link to={route.url}>
                    <div className="parent-icon"><i className={route.class}></i>
                    </div>
                    <div className="menu-title">{route.name}</div>
                </Link>
            </li>
        }
    </>
    )
}

export default SingleRoute;