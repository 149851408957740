import { url } from "../url/url";
export const Title = `Delivery - Size rank.`;
export const Requires = `requires authentication`;
export const Content = `Get the number of orders for every size.`;
export const Method = "GET";
export const Url = `api/delivery/sizesrank`;
export const Bash =
    `curl --request GET 
    --get "${url}/api/delivery/sizesrank?user=94" 
    --header "Api-Key: {YOUR_AUTH_KEY}" 
    --header "Content-Type: application/json" 
    --header "Accept: application/json"          
 `;
export const JavaScript = `
const url = new URL(
    "${url}/api/delivery/sizesrank?user=94"
);

const params = {
    "user": "94",
};
Object.keys(params)
    .forEach(key => url.searchParams.append(key, params[key]));

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `

[
    {
        "name": "Salé",
        "reg": "Rabat-Salé-Kénitra",
        "delivered": 90
    },
    {
        "name": "Rabat",
        "reg": "Rabat-Salé-Kénitra",
        "delivered": 50
    },
    {
        "name": "Casablanca",
        "reg": "Casablanca-Settat",
        "delivered": 30
    },
    {
        "name": "Tanger",
        "reg": "Tanger tetouan al hocaima",
        "delivered": 10
    },
    {
        "name": "Tetouan",
        "reg": "Tanger tetouan al hocaima",
        "delivered": 8
    }
    ]
`
export const QueryParameters = [
    {
        "id": 0,
        "name": "user",
        "ParamType": "string",
        "Description": "id of the user to get it's statistics.",
        "Required": "Yes",
    }
];
export const CSharp = `
var client = new HttpClient();
    client.BaseAddress = new Uri("${url}");
    client.DefaultRequestHeaders.Accept.Clear();
    client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
    client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
    client.DefaultRequestHeaders.Add("Content-Type", "application/json");
Dictionary<string, string> params = new Dictionary<string, string>();
        params.Add("user","94");
string url="/api/delivery/sizesrank?";
foreach(var item in params)
    {    url+=item.Key+"="+item.Value+"&";    }  
HttpResponseMessage response = await client.GetAsync(url);
return response;
}
`
export const java = `
URL url = new URL("${url}/api/delivery/sizesrank?");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
con.setRequestMethod("POST");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
    con.setDoOutput(true);
Map<String, String> parameters = new HashMap<>();
    parameters.put("user", "94"); 
StringBuilder result = new StringBuilder();
for (Map.Entry<String, String> entry : parameters.entrySet()) {
          result.append(URLEncoder.encode(entry.getKey(), "UTF-8"));
          result.append("=");
          result.append(URLEncoder.encode(entry.getValue(), "UTF-8"));
          result.append("&");
    }    
String resultString = result.toString();
    resultString = resultString.length() > 0
          ? resultString.substring(0, resultString.length() - 1)
          : resultString;
DataOutputStream out = new DataOutputStream(con.getOutputStream());
    out.writeBytes(resultString);
    out.flush();
    out.close();      
return con.getResponseMessage();`;