import React, { useEffect } from 'react'
import { intro, Warninig, Warninig2 } from '../../data/introduction/Introduction'

const Introduction = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="page-wrapper">
            <div className="page-content-wrapper pb-10 px-10 space-y-11">
                <div className='pt-3 pb-10 px-10 space-y-11 bg-light'>
                    <div><h4 className="text-2xl">Introduction</h4></div>
                    <div className="bg-light px-6 py-8 rounded-2xl ">
                        {intro}
                    </div>
                    <hr />
                    <div className="alert bg-light border-4 border-l-red-600" role="alert">
                        <p>{Warninig}<br />{Warninig2}</p>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Introduction;


