import React from "react";
import Navbar from "./components/Navbar";
import { Routes, Route, HashRouter } from "react-router-dom";
import Introduction from "./components/introduction/Introduction";
import AuthenticatingRequests from "./components/authenticating_requests/AuthenticatingRequests";
import Cites from "./components/essential_endpoints/Cities";
import Regiones from "./components/essential_endpoints/Regiones";
import Stations from "./components/essential_endpoints/Stations";
import OrderStatuses from "./components/essential_endpoints/OrderStatuses";
import Orderslist from "./components/orders/Orderslist";
import AddAnOrder from "./components/orders/AddAnOrder";
import ShowAnOrder from "./components/orders/ShowAnOrder";
import OrderTimeLine from "./components/orders/OrderTimeLine";
import UpdateAnOrder from "./components/orders/UpdateAnOrder";
import RetrieveAnOrder from "./components/orders/RetrieveAnOrder";
import UserOrdersList from "./components/orders/UserOrdersList";
import OrderLabel from "./components/orders/OrderLabel";
import AddAUser from "./components/users/AddAUser";
import UpdateAUser from "./components/users/UpdateAUser";
import BanAUser from "./components/users/BanAUser";
import PermitAUser from "./components/users/PermitAUser";
import DeliveryOrdersbySatus from "./components/statistics/DeliveryOrdersbySatus";
import DeliveryDeliveryPerformance from "./components/statistics/DeliveryDeliveryPerformance";
import DeliveryDeliveryRate from "./components/statistics/DeliveryDeliveryRate";
import DeliveryTopCities from "./components/statistics/DeliveryTopCities";
import DeliverySizeRank from "./components/statistics/DeliverySizeRank";
import RevenueRevenueByWeekDays from "./components/statistics/RevenueRevenueByWeekDays";
import RevenueWeekRevenue from "./components/statistics/RevenueWeekRevenue";
import RevenueMonthPayments from "./components/statistics/RevenueMonthPayments";
import RevenueRevenueBySize from "./components/statistics/RevenueRevenueBySize";
import RevenueGrowthByMonth from "./components/statistics/RevenueGrowthByMonth";
import Page404 from "./components/Page404.jsx";
import "./prism/prism.css";

function App() {
  return (
    <HashRouter hashType="noslash">
      <Navbar />
      <Routes>
        <Route path="/" element={<Introduction />} />
        <Route
          path="/authenticating_requests"
          element={<AuthenticatingRequests />}
        />
        <Route path="/cities" element={<Cites />} />
        <Route path="/regions" element={<Regiones />} />
        <Route path="/Stations" element={<Stations />} />
        <Route path="/order_statuses" element={<OrderStatuses />} />
        <Route path="/orders_list" element={<Orderslist />} />
        <Route path="/add_an_order" element={<AddAnOrder />} />
        <Route path="/show_an_order" element={<ShowAnOrder />} />
        <Route path="/order_timeline" element={<OrderTimeLine />} />
        <Route path="/update_an_order" element={<UpdateAnOrder />} />
        <Route path="/retrieve_an_order" element={<RetrieveAnOrder />} />
        <Route path="/a_user_orders_list" element={<UserOrdersList />} />
        <Route path="/order_label" element={<OrderLabel />} />
        <Route path="/add_a_user" element={<AddAUser />} />
        <Route path="/update_a_user" element={<UpdateAUser />} />
        <Route path="/ban_a_user" element={<BanAUser />} />
        <Route path="/permit_a_user" element={<PermitAUser />} />
        <Route path="/orders_by_status" element={<DeliveryOrdersbySatus />} />
        <Route
          path="/delivery_performance"
          element={<DeliveryDeliveryPerformance />}
        />
        <Route path="/delivery_rate" element={<DeliveryDeliveryRate />} />
        <Route path="/top_cities" element={<DeliveryTopCities />} />
        <Route path="/size_rank" element={<DeliverySizeRank />} />
        <Route
          path="/revenueby_week_days"
          element={<RevenueRevenueByWeekDays />}
        />
        <Route path="/week_revenue" element={<RevenueWeekRevenue />} />
        <Route path="/month_payments" element={<RevenueMonthPayments />} />
        <Route path="/revenue_by_size" element={<RevenueRevenueBySize />} />
        <Route path="/growth_by_month" element={<RevenueGrowthByMonth />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </HashRouter>
  );
}
export default App;
