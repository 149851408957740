export var OurRoutes = [
    {
        "id": 0,
        "name": "Introduction",
        "url": "/",
        "class": "lni lni-ruler-pencil"
    }
    ,
    {
        "id": 1,
        "name": "Authenticating requests",
        "url": "/authenticating_requests",
        "class": "fadeIn animated bx bx-check-shield"

    }
    ,
    {
        "id": 2,
        "essential_endpoints": {
            "name": "Essential endpoints",
            "url": "essential_endpoints",
            "class": "lni lni-ux"
        },
        "child":
            [{
                "name": "Cities",
                "url": "/Cities",
                "method": "GET"
            },
            {
                "name": "Regions",
                "url": "/regions",
                "method": "GET"
            },
            {
                "name": "Stations",
                "url": "/stations",
                "method": "GET"
            },
            {
                "name": "Order Statuses",
                "url": "/order_statuses",
                "method": "GET"
            }
            ]
    }
    ,
    {
        "id": 3,
        "orders": {
            "name": "Orders",
            "url": "/orders",
            "class": "fadeIn animated bx bx-cube-alt"
        },
        "child":
            [{
                "name": "Orders list",
                "url": "/orders_list",
                "method": "GET"
            },
            {
                "name": "Add an order",
                "url": "/add_an_order",
                "method": "POST"
            },
            {
                "name": "Show an order",
                "url": "/show_an_order",
                "method": "GET"
            },
            {
                "name": "Order timeline",
                "url": "/order_timeline",
                "method": "GET"
            },
            {
                "name": "Update an order",
                "url": "/update_an_order",
                "method": "POST"
            },
            {
                "name": "Retrieve an Order",
                "url": "/retrieve_an_order",
                "method": "GET"
            },
            {
                "name": "A user orders list",
                "url": "/a_user_orders_list",
                "method": "GET"
            },
            {
                "name": "Order Label",
                "url": "/order_label",
                "method": "GET"
            }
            ]
    }
    ,
    {
        "id": 4,
        "users": {
            "name": "Users",
            "url": "users",
            "class": "lni lni-users"
        },
        "child":
            [{
                "name": "Add a User",
                "url": "/add_a_user",
                "method": "POST"
            },
            {
                "name": "Update a User",
                "url": "/update_a_user",
                "method": "POST"
            },
            {
                "name": "Ban a User",
                "url": "/ban_a_user",
                "method": "GET"
            },
            {
                "name": "Permit a User",
                "url": "/permit_a_user",
                "method": "GET"
            }
            ]
    }
    ,
    {
        "id": 5,
        "statistics": {
            "name": "statistics",
            "url": "/statistics",
            "class": "lni lni-stats-up"
        },
        "child":
            [

                {
                    "id": 0,
                    "Delivery": {
                        "name": "Delivery",
                        "url": "/Delivery",
                        "class": "lni lni-delivery"
                    },
                    "subchild": [
                        {
                            "name": "Orders by status",
                            "url": "/orders_by_status",
                            "method": "GET"
                        },
                        {
                            "name": "Delivery performancer",
                            "url": "/delivery_performance",
                            "method": "GET"
                        },
                        {
                            "name": "Delivery rate",
                            "url": "/delivery_rate",
                            "method": "GET"
                        },
                        {
                            "name": "Top cities",
                            "url": "/top_cities",
                            "method": "GET"
                        },
                        {
                            "name": "Size rank",
                            "url": "/size_rank",
                            "method": "GET"
                        }
                    ]
                }
                ,
                {
                    "id": 1,
                    "Revenue": {
                        "name": "Revenue",
                        "url": "/Revenue",
                        "class": "lni lni-money-protection"
                    },
                    "subchild": [
                        {
                            "name": "Revenue by week days",
                            "url": "/revenueby_week_days",
                            "method": "GET"
                        },
                        {
                            "name": "Week revenue",
                            "url": "/week_revenue",
                            "method": "GET"
                        },
                        {
                            "name": "Month payments",
                            "url": "/month_payments",
                            "method": "GET"
                        },
                        {
                            "name": "Revenue by size",
                            "url": "/revenue_by_size",
                            "method": "GET"
                        },
                        {
                            "name": "Growth by month",
                            "url": "/growth_by_month",
                            "method": "GET"
                        }
                    ]
                }
                ,
            ]
    }
]
