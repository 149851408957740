import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { url } from "../data/url/url";
import { SiInstagram, SiFacebook, SiYoutube } from "react-icons/si";
import { FaLinkedinIn } from "react-icons/fa";
import "./style404.css";

function Body() {
  useEffect(() => {
    document.getElementById("Mynav").style.display = "none";
    document.getElementById("headnav").style.visibility = "hidden";
    return () => {
      document.getElementById("Mynav").style.display = "block";
      document.getElementById("headnav").style.visibility = "visible";
    };
  });

  return (
    <div className="min-h-screen flex flex-col justify-center content-center items-center px-5 text-center">
      <h1 className="text-9xl font-bold mb-3">404</h1>
      <h1 className="text-3xl font-bold">404 NOT FOUND</h1>
      <h1 className="text-xl ">
        SORRY, AN ERROR HAS OCCURED, REQUESTED PAGE NOT FOUND!
      </h1>
      <Link to="/" className="m-10 py-2 px-6 rounded-full myback">
        Go Home
      </Link>
      <h1 className="opacity-80">
        Copyright © {new Date().getFullYear()} TLOG.ma | All rights reserved.
      </h1>
      <br />
      <hr className="w-50 border-light" />
      <ul className="w-52 flex justify-around">
        <li className="p-2 myback rounded-full cursor-pointer">
          <a
            href="https://www.instagram.com/tlog.ma/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiInstagram />
          </a>
        </li>
        <li className="p-2 myback rounded-full cursor-pointer">
          <a
            href="https://www.facebook.com/Tlog.ma"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiFacebook />
          </a>
        </li>
        <li className="p-2 myback rounded-full cursor-pointer">
          <a
            href="https://www.linkedin.com/company/tlogmaroc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn />
          </a>
        </li>
        <li className="p-2 myback rounded-full cursor-pointer">
          <a
            href="https://www.youtube.com/channel/UC5yC26u9vQcmN1nD9Ie1Ptw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiYoutube />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Body;
