import { url } from "./../url/url";
export const Title = `Order Statuses`;
export const Requires = `requires authentication`;
export const Content = `Get a list of order possible statuses`;
export const Method = `GET`;
export const Url = `api/essentials/statuses`;
export const Bash =
    `curl --request GET 
         --get "${url}/api/essentials/statuses" 
         --header "Api-Key: {YOUR_AUTH_KEY}" 
         --header "Content-Type: application/json" 
         --header "Accept: application/json"`;
export const JavaScript = `
const url = new URL(
    "${url}/api/essentials/statuses"
);
const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `[
    {
        "id": 1,
        "text": "En-attente"
    },
    {
        "id": 2,
        "text": "En-transit"
    },
    {
        "id": 3,
        "text": "Terminé"
    },
    {
        "id": 4,
        "text": "En-livraison"
    },
    {
        "id": 5,
        "text": "Livré"
    },
    {
        "id": 6,
        "text": "Annuler"
    },
    {
        "id": 7,
        "text": "Reporter"
    },
    {
        "id": 8,
        "text": "En-ramassage"
    },
    {
        "id": 9,
        "text": "À-l'entrepôt"
    },
    {
        "id": 10,
        "text": "Encaissé a l'expéditeur"
    },
    {
        "id": 11,
        "text": "Retour à l'expéditeur"
    },
    {
        "id": 12,
        "text": "Renvoyé à l'expéditeur"
    },
    {
        "id": 13,
        "text": "Perdu"
    },
    
    {
        "id": 15,
        "text": "Prêt à inverser"
    },
    {
        "id": 16,
        "text": "En-exception"
    },
    {
        "id": 17,
        "text": "Hors zone"
    },
    {
        "id": 19,
        "text": "Cancelled"
    },
    {
        "id": 21,
        "text": "Injoignable"
    }
]
`;
export const CSharp = `
var client = new HttpClient();
    client.BaseAddress = new Uri("${url}");
    client.DefaultRequestHeaders.Accept.Clear();
    client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
    client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
    client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = await client.GetAsync("/api/essentials/statuses");
return response;
}
`
export const java = `
URL url = new URL("${url}/api/essentials/statuses");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
    con.setRequestMethod("GET");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
return con.getResponseMessage();
`