import { url } from "../url/url";
export const Title = `Permit a User.`;
export const Requires = `requires authentication`;
export const Content = `a permited user would be able to add and update packages`;
export const Method = "GET";
export const Url = `api/user/{id}/permit`;
export const Bash =
    `curl --request GET 
          --get "${url}/api/user/omnis/permit" 
          --header "Api-Key: {YOUR_AUTH_KEY}" 
          --header "Content-Type: application/json" 
          --header "Accept: application/json"   `;

export const JavaScript = `const url = new URL(
    "${url}/api/user/omnis/permit"
);

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `
{
    "message": "User activated !"
}
`
export const Response404 = `
{
    "message": "User not found !"
}
`
export const URLParameters = [{ "id": "string" }];

export const Java = `
URL url = new URL("${url}/api/user/omnis/permit");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
    con.setRequestMethod("GET");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
return con;
`
export const CSharp = `
var client = new HttpClient();
    client.BaseAddress = new Uri("${url}");
    client.DefaultRequestHeaders.Accept.Clear();
    client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
    client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
    client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = await client.GetAsync("/api/user/omnis/permit");
return con.getResponseMessage();
`