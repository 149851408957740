import { url } from "../url/url";
export const Title = `Regions`;
export const Requires = `requires authentication`;
export const Content = `Get the list of regions we cover`;
export const Method = `GET`;
export const Url = `api/essentials/regions`;
export const Bash =
    `curl --request GET 
          --get "${url}/api/essentials/regions" 
          --header "Api-Key: {YOUR_AUTH_KEY}" 
          --header "Content-Type: application/json" 
          --header "Accept: application/json"      `;
export const JavaScript = `
const  url = new URL(
    "${url}/api/essentials/regions"
);
const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};
fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `
[
    {
        "id": "1",
        "text": "Tanger-Tétouan-Al Hoceïma"
    },
    {
        "id": "2",
        "text": "l'Oriental"
    },
    {
        "id": "3",
        "text": "Fès-Meknès"
    },
    {
        "id": "4",
        "text": "Rabat-Salé-Kénitra"
    },
    {
        "id": "5",
        "text": "Béni Mellal-Khénifra"
    },
    {
        "id": "6",
        "text": "Casablanca-Settat"
    },
    {
        "id": "7",
        "text": "Marrakech-Safi"
    },
    {
        "id": "8",
        "text": "Drâa-Tafilalet"
    },
    {
        "id": "9",
        "text": "Souss-Massa"
    },
    {
        "id": "10",
        "text": "Guelmim-Oued Noun"
    },
    {
        "id": "11",
        "text": "Laâyoune-Sakia El Hamra"
    },
    {
        "id": "12",
        "text": "Dakhla-Oued Ed Dahab"
    }
]
`

export const CSharp = `
var client = new HttpClient();
        client.BaseAddress = new Uri("${url}");
        client.DefaultRequestHeaders.Accept.Clear();
        client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
        client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
        client.DefaultRequestHeaders.Add("Content-Type", "application/json");
        HttpResponseMessage response = await client.GetAsync("/api/essentials/regions");
return response;
}
`
export const java = `
URL url = new URL("${url}/api/essentials/regions");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
    con.setRequestMethod("GET");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
return con.getResponseMessage();
`