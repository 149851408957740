import React, { useEffect } from 'react'
import { Content } from '../../data/authenticating_requests/AuthenticatingRequest';

function AuthenticatingRequests() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="page-wrapper">
            <div className="page-content-wrapper px-10 space-y-11">
                <div className='pt-3 pb-10 px-10 space-y-11 bg-light'>
                    <div><h4 className="text-2xl">Authenticating Requests</h4></div>
                    <div className="bg-light px-6 py-8 rounded-2xl ">
                        {Content}
                    </div>
                    <hr />
                </div>
            </div>
        </div>
    )
}

export default AuthenticatingRequests