import React, { Fragment, useEffect, useState } from "react";
import SingleRoute from "./routes/SingleRoute.js";
import MultipleRoutes from "./routes/MultipleRoutes";
import { OurRoutes } from "../data/OurRoutes.js";
import logo from "./img/Tlog_logo_white.png";
const Navbar = () => {
  const [GETroutes, SETRoutes] = useState();
  const [GETLoacl, SETLoacl] = useState();
  const [GetClass, SetClass] = useState("mm-show");
  const [GetActive, SetActive] = useState("mm-active");
  const [GETInputValue, SETInputValue] = useState();
  useEffect(() => {
    SETRoutes("");
    SETLoacl("");
    SETRoutes(OurRoutes);
    SETLoacl(OurRoutes);
    localStorage.setItem("Routes", JSON.stringify(OurRoutes));
  }, []);
  const RestoreState = () => {
    let storedArray = localStorage.getItem("Routes");
    SETLoacl([...JSON.parse(storedArray)]);
  };
  useEffect(() => {
    if (
      typeof GETroutes !== "undefined" &&
      typeof GETInputValue !== "undefined"
    ) {
      if (GETInputValue.length > 0) {
        RestoreState();
        const RoutesWithoutChild = Object(
          Object(GETLoacl).filter((route, key) => {
            if (!route.hasOwnProperty("child")) {
              return (
                String(route.name) !== "undefined" &&
                String(route.name)
                  .toLowerCase()
                  .includes(GETInputValue.toLowerCase())
              );
            } else {
              route.child = route.child.filter((name) => {
                if (!name.hasOwnProperty("subchild")) {
                  return String(name.name)
                    .toLowerCase()
                    .includes(GETInputValue.toLowerCase());
                } else {
                  name.subchild = name.subchild.filter((subname) => {
                    return String(subname.name)
                      .toLowerCase()
                      .includes(GETInputValue.toLowerCase());
                  });
                  if (name.subchild.length !== 0) {
                    return true;
                  } else {
                    return false;
                  }
                }
              });
              if (route.child.length === 0) {
                return false;
              } else {
                return true;
              }
            }
          })
        );
        SETRoutes([...RoutesWithoutChild]);
        RestoreState();
        SetClass("mm-show");
        SetActive("mm-active");
      } else {
        RestoreState();
        let storedArray = localStorage.getItem("Routes");
        SETRoutes([]);
        SETRoutes([...JSON.parse(storedArray)]);
        SetClass("mm-show");
        SetActive("mm-active");
      }
    } else {
      SetClass("");
      SetActive("");
    }
  }, [GETInputValue]);

  return (
    <>
      <div className="wrapper" style={{ height: "0%" }} id="Mynav">
        <div className="sidebar-wrapper" data-simplebar="true">
          <div className="sidebar-header md:p-0 md:flex md:content-center md:flex-col ">
            <div className="">
              <img src={logo} alt="Logo" style={{ width: "90px" }} />
            </div>
            <a
              href="javascript:;"
              className="toggle-btn ml-auto flex md:hidden"
            >
              <i className="bx bx-menu"></i>
            </a>
            <span className="font-bold text-center hidden md:flex">
              Provider API
            </span>
          </div>
          <ul className="metismenu" id="menu">
            <li>
              <input
                
                type="text" className="form-control backgroundColor"
                placeholder="Find anything"
                onInput={(e) => SETInputValue(e.target.value)}
              />
            </li>
            <div>
              {typeof GETroutes !== "undefined" &&
                Object.keys(GETroutes).length !== 0 &&
                Object(GETroutes).map((route, id) => {
                  if (!route.hasOwnProperty("child")) {
                    return (
                      <Fragment key={id}>
                        <SingleRoute route={route} />
                      </Fragment>
                    );
                  } else {
                    return (
                      <Fragment key={id}>
                        <MultipleRoutes
                          route={route}
                          ToggleLi={GetClass}
                          ToggleUl={GetActive}
                          isSubChild={false}
                        />
                      </Fragment>
                    );
                  }
                })}
            </div>
            <li className="border-y-2 border-double	border-red-500">
              <a
                href={process.env.PUBLIC_URL + "/files/Collection.json"}
                rel="noopener noreferrer"
                download
              >
                <div className="parent-icon"></div>
                <div className="menu-title text-xs">
                  View Postman collection
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <header className="top-header flex lg:hidden" id="headnav">
        <nav className="navbar navbar-expand">
          <div className="left-topbar d-flex align-items-center">
            <a href="javascript:;" className="toggle-btn">
              <i className="bx bx-menu"></i>
            </a>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
