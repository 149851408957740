import { url } from "../url/url";
export const Title = `Retrieve an Order`;
export const Requires = `requires authentication`;
export const Content = `Create a reverse order for an already exists order`;
export const Method = "GET";
export const Url = `api/order/retrieve/{tracking}`;
export const Bash =
    `curl --request GET 
    --get "${url}/api/order/retrieve/minus" 
    --header "Api-Key: {YOUR_AUTH_KEY}" 
    --header "Content-Type: application/json" 
    --header "Accept: application/json"      `;

export const JavaScript = `
const url = new URL(
    "${url}/api/order/retrieve/minus"
);

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/pdf",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `
{
    "message": "Success",
    "order": {
        "tracking": "RTC-IpcrP8GK",
        "order_number": "CMD-77894163",
        "name": "nace 3",
        "lastname": "omnis",
        "adress": "odit idit aa aaddsa",
        "city": 4,
        "amount": 0,
        "phone": "0600000000",
        "hub": 1,
        "shipper": 94,
        "status": 1,
        "size": "small",
        "delivery_type": "Reverse",
        "article": "None",
        "code_rtc": "RTC-93052667",
        "updated_at": "2022-01-31T16:38:40.000000Z",
        "created_at": "2022-01-31T16:38:40.000000Z",
        "id": 1327,
        "order_city": "Salé",
        "order_status": "En-attente",
        "order_region": "Tanger-Tétouan-Al Hocaima",
        "order_company": "SHOPNAME",
        "first_mile": "Salé",
        "last_mile": "Salé"
    }
}
`
export const URLParameters = [{ 'tracking': 'string' }];
export const CSharp = `
var client = new HttpClient();
        client.BaseAddress = new Uri("${url}");
        client.DefaultRequestHeaders.Accept.Clear();
        client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
        client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
        client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = await client.GetAsync("/api/order/retrieve/minus");
return response;
`;
export const java = `
URL url = new URL("${url}/api/order/retrieve/minus");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
    con.setRequestMethod("GET");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
return con.getResponseMessage();
`