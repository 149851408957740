import React, { useEffect } from 'react'
import { Title, Requires, Content, Method, Url, Bash, JavaScript, Response, QueryParameters, CSharp, java } from "../../data/statistics/DeliveryDeliveryPerformance"
import { SiCsharp, SiGnubash, SiJava, SiJavascript } from "react-icons/si";
import Prism from "prismjs"
import QueryTable from '../table/QueryTable';
function DeliveryDeliveryPerformance() {
    useEffect(() => {
        Prism.highlightAll();
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="page-wrapper">
            <div className="page-content-wrapper pb-10 px-10 space-y-11">
                <div className='pt-4 pb-10 px-10 space-y-11 space-x-5 bg-light'>
                    <div className="space-x-5 space-y-7">
                        <h4 className="text-2xl">Statistics</h4>
                        <h4 className="text-2xl ">{Title}</h4>
                        <div className="px-2 py-2 space-y-4">
                            <span className='px-2 py-2 font-bold bg-red-700 rounded-full'> {Requires}</span>
                            <p className='px-2 py-2 Font-bold'>{Content}</p>
                            <h1 className='px-2 py-2 font-mono Font-bold'>Request </h1>
                            <h1><span className='text-white border-white text-xs p-1 border-2 
                     font-bold rounded mr-3'>
                                {Method}</span>
                                {Url}</h1>
                        </div>
                        <div className='px-2  rounded-2xl'>
                            <h1>Body Parameters</h1>
                            <QueryTable QueryParameters={QueryParameters} />
                        </div>
                    </div>
                    <hr />
                    <div className="card mt-3 tab-card min-w-full">
                        <div className="card-header tab-card-header">
                            <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link flex justify-center items-center space-x-1 active"
                                        id="one-tab"
                                        data-toggle="tab"
                                        href="#one"
                                        role="tab"
                                        aria-controls="One"
                                        aria-selected="true">
                                        <SiJavascript />  <span>JavaScript</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link flex justify-center items-center space-x-2" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">
                                        <SiGnubash />   <span>Bash</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link flex justify-center items-center space-x-2" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="false">
                                        <SiJava />   <span>Java</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link flex justify-center items-center space-x-2" id="four-tab" data-toggle="tab" href="#four" role="tab" aria-controls="four" aria-selected="false">
                                        <SiCsharp />   <span>C#</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
                                <pre>
                                    <code className='language-javascript'> {JavaScript} </code>
                                </pre>
                            </div>
                            <div className="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <pre>
                                    <code className="language-javascript">
                                        {Bash}
                                    </code>
                                </pre>
                            </div>
                            <div className="tab-pane fade p-3" id="three" role="tabpanel" aria-labelledby="three-tab">
                                <pre>
                                    <code className="language-javascript">
                                        {java}
                                    </code>
                                </pre>
                            </div>
                            <div className="tab-pane fade p-3" id="four" role="tabpanel" aria-labelledby="four-tab">
                                <pre>
                                    <code className="language-javascript">
                                        {CSharp}
                                    </code>
                                </pre>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 className='px-2 py-2 font-mono text-3xl Font-bold'>Response <br />
                            <h1 className='px-2 py-2 font-mono text-xl Font-bold'>[200]<span className="text-green-500 capitalize text-sm">succeed</span></h1>  </h1>
                        <pre>
                            <code className="language-javascript">
                                {Response}
                            </code>
                        </pre>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DeliveryDeliveryPerformance;