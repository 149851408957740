import { url } from "../url/url";
export const Title = `Update a User`;
export const Requires = `requires authentication`;
export const Content = `update a User informations`;
export const Method = "POST";
export const Url = `api/user/{id}/update`;
export const Bash =
    `curl --request POST 
    "${url}/api/user/reprehenderit/update" 
    --header "Api-Key: {YOUR_AUTH_KEY}" 
    --header "Content-Type: application/json" 
    --header "Accept: application/json" 
    --data "{
    "name": "eos",
    "lastname": "nemo",
    "email": "sequi",
    "phone": "minima",
    "adresse": "et",
    "company": "quis",
    "city": "inventore",
    "cin": "odio",
    "website": "sed",
    "adresse_cin": "quaerat"
}"`;

export const JavaScript = `const url = new URL(
    "${url}/api/user/reprehenderit/update"
);
const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};
let body = {
    "name": "eos",
    "lastname": "nemo",
    "email": "sequi",
    "phone": "minima",
    "adresse": "et",
    "company": "quis",
    "city": "inventore",
    "cin": "odio",
    "website": "sed",
    "adresse_cin": "quaerat"
};
fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
}).then(response => response.json());
`
export const URLParameters = [{ "id": "string" }]

export const Response = `
{
    "message": "User added",
    "user_id": 2022
}
{
    "message": "User updated",
    "user": {
        "id": 90,
        "name": "name name",
        "lastname": "last name lastname",
        "email": "mail@email.com",
        "tel": "0600000000",
        "adresse": "adresse adresse adresse adresse",
        "company": "SHOPNAME",
        "status": "active",
        "website": "website",
        "created_at": "2022-01-25T15:31:16.000000Z",
        "updated_at": "2022-01-25T16:06:58.000000Z"
    }
}
`
export const Response500 = `{
    "message": "User not found or can't be updated"
}
`
export const BodyParameters =
    [
        {
            "id": 0,
            "name": "name",
            "ParamType": "string",
            "Description": "must be at least 3 characters",
            "Required": "no",

        }
        ,
        {
            "id": 1,
            "name": "lastname",
            "ParamType": "string",
            "Description": "must be at least 3 characters",
            "Required": "no",

        }
        ,
        {
            "id": 2,
            "name": "email",
            "ParamType": "string",
            "Description": "unique | valid email address",
            "Required": "Yes",

        }
        ,
        {
            "id": 3,
            "name": "tel",
            "ParamType": "string",
            "Description": "valid phone number",
            "Required": "no",
        }
        ,
        {
            "id": 4,
            "name": "adresse",
            "ParamType": "string",
            "Description": "pickup address must be at least 15 characters",
            "Required": "no",
        }
        ,
        {
            "id": 5,
            "name": "company",
            "ParamType": "string",
            "Description": "name of store or company",
            "Required": "no",
        }
        ,
        {
            "id": 6,
            "name": "city",
            "ParamType": "string",
            "Description": "pickup city (should match our records; see the cities route in essentials)",
            "Required": "no",
        }
        ,
        {
            "id": 7,
            "name": "cin",
            "ParamType": "string",
            "Description": "cin",
            "Required": "no",
        }
        ,
        {
            "id": 8,
            "name": "website",
            "ParamType": "string",
            "Description": "website",
            "Required": "no",
        }
        ,
        {
            "id": 9,
            "name": "adresse_cin",
            "ParamType": "string",
            "Description": "adresse_cin",
            "Required": "no",
        }

    ]
export const java = `
URL url = new URL("${url}/api/user/reprehenderit/update");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
con.setRequestMethod("POST");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
    con.setDoOutput(true);
    String data = "{
                    "name": "eos",
                    "lastname": "nemo",
                    "email": "sequi",
                    "phone": "minima",
                    "adresse": "et",
                    "company": "quis",
                    "city": "inventore",
                    "cin": "odio",
                    "website": "sed",
                    "adresse_cin": "quaerat"
                    }";                   
 byte[] out = data.getBytes(StandardCharsets.UTF_8);
 OutputStream stream = con.getOutputStream();
 stream.write(out);
 return con.getResponseCode();
    `
export const CSharp = `
var client = new HttpClient();
User user = new User() ;
        user.name = "eos";
        user.lastname = "nemo";
        user.email = "sequi",
        user.phone = "minima";
        user.adresse = "et";
        user.company = "quis";
        user.city = "inventore";
        user.cin = "odio";
        user.website = "sed";
        user.adresse_cin = "quaerat";
var data = user;
           client.BaseAddress = new Uri("${url}");
           client.DefaultRequestHeaders.Accept.Clear();
           client.DefaultRequestHeaders.Accept.Add(newMediaTypeWithQualityHeaderValue("application/json"));
           client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
           client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = awaitclient.PostAsJsonAsync("/api/user/reprehenderit/update", data);
return con.getResponseMessage();
`
