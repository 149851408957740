import { url } from "../url/url";
export const Title = `Add a user`;
export const Requires = `requires authentication`;
export const Content = `add a User informations`;
export const Method = "POST";
export const Url = `api/users/add`;
export const Bash =
    `curl --request POST 
    "${url}/api/users/add" 
    --header "Api-Key: {YOUR_AUTH_KEY}" 
    --header "Content-Type: application/json" 
    --header "Accept: application/json" 
    --data "{
    "name": "molestiae",
    "lastname": "et",
    "email": "sequi",
    "tel": "non",
    "adresse": "qui",
    "company": "laboriosam",
    "city": "distinctio",
    "cin": "rem",
    "website": "sequi",
    "adresse_cin": "et"
}"`;

export const JavaScript = `
const url = new URL(
    "${url}/api/users/add"
);
const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};
let body = {
    "name": "molestiae",
    "lastname": "et",
    "email": "sequi",
    "tel": "non",
    "adresse": "qui",
    "company": "laboriosam",
    "city": "distinctio",
    "cin": "rem",
    "website": "sequi",
    "adresse_cin": "et"
};
fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
}).then(response => response.json());
`
export const Response = `
{
    "message": "User added",
    "user_id": 2022
}
`
export const Response400 = `
scenatio="Invalid data"
 {"message":"The provided data is invalid !"}

`
export const BodyParameters =
    [
        {
            "id": 0,
            "name": "name",
            "ParamType": "string",
            "Description": "must be at least 3 characters",
            "Required": "Yes",
        }
        ,
        {
            "id": 1,
            "name": "lastname",
            "ParamType": "string",
            "Description": "must be at least 3 characters",
            "Required": "No",
        }
         ,
        {
            "id": 2,
            "name": "email",
            "ParamType": "string",
            "Description": "unique | valid email address",
            "Required": "Yes",

        }
        ,
        {
            "id": 3,
            "name": "tel",
            "ParamType": "string",
            "Description": "valid phone number",
            "Required": "Yes",
        }
        ,
        {
            "id": 4,
            "name": "adresse",
            "ParamType": "string",
            "Description": "pickup address must be at least 15 characters",
            "Required": "Yes",
        }
        ,
        {
            "id": 5,
            "name": "company",
            "ParamType": "string",
            "Description": "name of store or company",
            "Required": "No",
        }
        ,
        {
            "id": 6,
            "name": "city",
            "ParamType": "string",
            "Description": "pickup city (should match our records; see the cities route in essentials)",
            "Required": "Yes",
        }
        ,
        {
            "id": 7,
            "name": "cin",
            "ParamType": "string",
            "Description": "cin",
            "Required": "No",
        }
        ,
        {
            "id": 8,
            "name": "website",
            "ParamType": "string",
            "Description": "website",
            "Required": "No",
        }
        ,
        {
            "id": 9,
            "name": "adresse_cin",
            "ParamType": "string",
            "Description": "adresse_cin",
            "Required": "No",
        }

    ]
export const java = `
URL url = new URL("${url}/api/users/add");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
con.setRequestMethod("POST");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
    con.setDoOutput(true);
String data =  "{
                  "name": "molestiae",
                  "lastname": "et",
                  "email": "sequi",
                  "tel": "non",
                  "adresse": "qui",
                  "company": "laboriosam",
                  "city": "distinctio",
                  "cin": "rem",
                  "website": "sequi",
                  "adresse_cin": "et"
                }";
byte[] out = data.getBytes(StandardCharsets.UTF_8);
 OutputStream stream = http.getOutputStream();
 stream.write(out);
 return con.getResponseMessage();
`
export const CSharp = `
var client = new HttpClient();
User user = new User() ;
     user.name = "eos";
     user.lastname = "nemo";
     user.email: "sequi",
     user.phone = "minima";
     user.adresse = "et";
     user.company = "quis";
     user.city = "inventore";
     user.cin = "odio";
     user.website = "sed";
     user.adresse_cin = "quaerat";
var data = user;
        client.BaseAddress = new Uri("${url}");
        client.DefaultRequestHeaders.Accept.Clear();
        client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
        client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
        client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = await client.PostAsJsonAsync("/api/users/add", data);
return response;
`
