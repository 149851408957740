import { url } from "../url/url";
export const Title = `Add an order`;
export const Requires = `requires authentication`;
export const Content = `add an order informations`;
export const Method = "POST";
export const Url = `api/orders/add`;
export const Bash =
    `curl --request POST 
          "${url}/api/orders/add" 
          --header "Api-Key: {YOUR_AUTH_KEY}" 
          --header "Content-Type: application/json" 
          --header "Accept: application/json" 
          --data "{
          "user": "enim",
          "orders": [{
              "name": "eos",
              "lastname": "laudantium",
              "adresse": "magnam",
              "size": "sed",
              "city": 2,
              "operation": "dolor",
              "amount": 33032.21318,
              "can_open": true,
              "pickup": [{
                "city": "nemo",
                "location": "laudantium",
                "phone": "0X XXXXXXXX",
            }],
          }]
}`;
export const JavaScript = `
const url = new URL(
    "${url}/api/orders/add"
);
const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};
let body = {
    "user": "enim",
    "orders": [{
        "name": "eos",
        "lastname": "laudantium",
        "adresse": "magnam",
        "size": "sed",
        "city": 2,
        "operation": "dolor",
        "amount": 33032.21318,
        "can_open": true,
        "pickup": [{
            "city": "nemo",
            "location": "laudantium",
            "phone": "0X XXXXXXXX",
        }],
    }]
};
fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
}).then(response => response.json());
`
export const Response = `{
    "message":"Orders submited",
    "orders":["DOT-P7GuGfpZ","DOT-OI9t0MME","DOT-PdFL3e7J",
    "DOT-pm2yTene"]
}
`
export const Response400 = `{
    "message": "The provided data is invalid !"
}
`
export const QueryParameters = [
    {
        "id": 0,
        "name": "user",
        "ParamType": "string",
        "Description": "id that matches the user in our records",
        "Required": "Yes",

    },
    {
        "id": 0,
        "name": "orders",
        "ParamType": "Array Of order",
        "Description": "Array of list orders scheme below",
        "Required": "Yes",

    },
]


export const PickupScheme = [

    {
        "id": 9,
        "name": "pickup ",
        "ParamType": "object ",
        "Description": "if pickup exists,  city : required | phone: required | location: required",
        "Required": "No",
    }
]


export const OrdersScheme = [
    {
        "id": 1,
        "name": "name",
        "ParamType": "string",
        "Description": "must be at least 3 character",
        "Required": "Yes",

    }
    ,
    {
        "id": 2,
        "name": "lastname",
        "ParamType": "string",
        "Description": "must be at least 3 character",
        "Required": "No",

    },
    {
        "id": 3,
        "name": "adresse",
        "ParamType": "string",
        "Description": "destination must be at least 15 characters",
        "Required": "Yes",

    },
    {
        "id": 4,
        "name": "size",
        "ParamType": "string",
        "Description": "must be one of these : small | meduim | extra",
        "Required": "No",

    },
    {
        "id": 5,
        "name": "city",
        "ParamType": "string",
        "Description": "destination city (should match our records; see the cities route in essentials)",
        "Required": "Yes",

    },
    {
        "id": 6,
        "name": "operation",
        "ParamType": "string",
        "Description": "Should be 'Forward' ",
        "Required": "No",

    },
    {
        "id": 7,
        "name": "amount ",
        "ParamType": "number ",
        "Description": "amount to collect from the client",
        "Required": "Yes",
    },
    {
        "id": 8,
        "name": "can_open ",
        "ParamType": "boolean ",
        "Description": "0 or 1 to authorize package opening",
        "Required": "Yes",
    },
  

]



export const CSharp = `
var client = new HttpClient();
Order order = new Order() ;
      order.name = "quo";
      order.lastname = "doloribus";
      order.adresse = "consequatur";
      order.size = "illo";
      order.city = 2;
      order.amount = 153.10421127;
      order.can_open = 1;
      order.pickup: [{
        "city": "nemo",
        "location": "laudantium",
        "phone": "0X XXXXXXXX",
    }],
List <Order> lsOrder = new List<Order>(); 
     lsOrder.add(order) ;    
 AddOrder orderAdd =new AddOrder()  ;   
      orderAdd.user=this.user;
      orderAdd.listOrders=lsOrder;
var data = lsOrder;
     client.BaseAddress = new Uri("${url}");
     client.DefaultRequestHeaders.Accept.Clear();
     client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
     client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
     client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = await client.PostAsJsonAsync("/api/orders/add", data);
return response;
`;
export const java = `
URL url = new URL("${url}/api/orders/add");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
con.setRequestMethod("POST");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
    con.setDoOutput(true);
String data = "{"user": "enim", 
                "orders":[ {    
                     "name": "eos", 
                     "lastname": "laudantium",  
                     "adresse": "magnam",     
                     "size": "sed",      
                     "city": 2,      
                    "operation": "dolor",  
                    "amount": 33032.21318, 
                    "can_open": true,
                    "pickup": [{
                        "city": "nemo",
                        "location": "laudantium",
                        "phone": "0X XXXXXXXX",
                    }],   
                }]
                }";
byte[] out = data.getBytes(StandardCharsets.UTF_8);
 OutputStream stream = con.getOutputStream();
 stream.write(out);
 return con.getResponseMessage();
`;