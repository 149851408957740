import { url } from "./../url/url"
export const Title = `Cities`;
export const Requires = `requires authentication`;
export const Content = `Get the list of cities we cover`;
export const Method = `GET`;
export const Url = `api/essentials/cities`;
export const Bash = `
     curl --request GET 
          --get "${url}/api/essentials/cities" 
          --header "Api-Key: {YOUR_AUTH_KEY}" 
          --header "Content-Type: application/json" 
          --header "Accept: application/json"`;
export const JavaScript = `
const url = new URL(
    "${url}/api/essentials/cities"
);

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `
[
    {
        "id": "1",
        "text": "BOUSKOURA",
        "station": "Casablanca-Station"
    },
    {
        "id": "2",
        "text": "CASABLANCA",
        "station": "Casablanca-Station"
    },
    {
        "id": "3",
        "text": "MOHAMMADIA",
        "station": "Casablanca-Station"
    },
    {
        "id": "4",
        "text": "BOUZNIKA",
        "station": "Casablanca-Station"
    },
    {
        "id": "5",
        "text": "Kénitra",
        "station": "Salé-Station"
    },
    {
        "id": "6",
        "text": "Mechra Bel Ksiri",
        "station": "Meknès-Station"
    },
    {
        "id": "7",
        "text": "Mehdia",
        "station": "Salé-Station"
    }
]
`
export const CSharp = `
    var client = new HttpClient();
        client.BaseAddress = new Uri("${url}");
        client.DefaultRequestHeaders.Accept.Clear();
        client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
        client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
        client.DefaultRequestHeaders.Add("Content-Type", "application/json");
    HttpResponseMessage response = await client.GetAsync("/api/essentials/cities");
    return response;
`
export const java = `
URL url = new URL("${url}/api/essentials/cities");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
    con.setRequestMethod("GET");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
return con.getResponseMessage();
`

