import { url } from "../url/url";
export const Title = `Add an order`;
export const Requires = `requires authentication`;
export const Content = `update an order informations`;
export const Method = "POST";
export const Url = `api/order/update/{tracking}`;
export const Bash = `
    curl --request POST 
    "${url}/api/order/update/tempora" 
    --header "Api-Key: {YOUR_AUTH_KEY}" 
    --header "Content-Type: application/json" 
    --header "Accept: application/json" 
    --data "{
    "name": "quo",
    "lastname": "doloribus",
    "adresse": "consequatur",
    "size": "illo",
    "city": 2,
    "amount": 153.10421127,
    "can_open": 1,
    "pickup": [{
      "city": "nemo",
      "location": "laudantium",
      "phone": "0X XXXXXXXX",
  }],
}"
    `;
export const JavaScript = `
const url = new URL(
    "${url}/api/order/update/tempora"
);

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

let body = {
    "name": "quo",
    "lastname": "doloribus",
    "adresse": "consequatur",
    "size": "illo",
    "city": 2,
    "amount": 153.10421127,
    "can_open": 1,
    "pickup": [{
      "city": "nemo",
      "location": "laudantium",
      "phone": "0X XXXXXXXX",
  }],
};

fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
}).then(response => response.json());

`;
export const URLParameters = [{ tracking: "string" }];
export const Response = `{
    "message": "Order updated"
}
`;
export const Response500 = `{
    "message": "user is banned"
}
`;
export const Response403 = `{"message":"Order can't be updated"}
`;
export const BodyParameters = [
  {
    id: 0,
    name: "name",
    ParamType: "string",
    Description: "must be at least 3 character",
    Required: "no",
  },
  {
    id: 1,
    name: "lastname",
    ParamType: "string",
    Description: "must be at least 3 characters",
    Required: "no",
  },
  {
    id: 2,
    name: "adresse",
    ParamType: "string",
    Description: "destination must be at least 15 characters",
    Required: "no",
  },
  {
    id: 3,
    name: "size",
    ParamType: "string",
    Description: "must be one of these : small | meduim | extra",
    Required: "no",
  },
  {
    id: 4,
    name: "city",
    ParamType: "integer",
    Description:
      "destination city (id should match our records; see the cities route in essentials)",
    Required: "no",
  },
  {
    id: 5,
    name: "amount",
    ParamType: "number",
    Description: "amount to collect from the client",
    Required: "no",
  },
  {
    id: 6,
    name: "can_open",
    ParamType: "number",
    Description: "0 or 1 to authorize package opening",
    Required: "no",
  },
];

export const CSharp = `
var client = new HttpClient();
Order order = new Order() ;
      order.name = "quo";
      order.lastname = "doloribus";
      order.adresse = "consequatur";
      order.size = "illo";
      order.city = 2;
      order.amount = 153.10421127;
      order.can_open = 1;
      order.pickup: [{
        "city": "nemo",
        "location": "laudantium",
        "phone": "0X XXXXXXXX",
    }],

var data = order;
      client.BaseAddress = new Uri("${url}");
      client.DefaultRequestHeaders.Accept.Clear();
      client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
      client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
      client.DefaultRequestHeaders.Add("Content-Type", "application/json");
HttpResponseMessage response = await client.PostAsJsonAsync("/api/order/update/tempora", data);
return response ;
`;
export const java = `
URL url = new URL("${url}/api/order/update/tempora");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
    con.setRequestMethod("POST");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
    con.setDoOutput(true);
String data = "{   "name": "quo",
                       "lastname": "doloribus", 
                       "adresse": "consequatur",
                       "size": "illo",    
                       "city": 2,    
                       "amount": 153.10421127,    
                       "can_open": 1,
                       "pickup": [{
                        "city": "nemo",
                        "location": "laudantium",
                        "phone": "0X XXXXXXXX",
                    }],
 }";
 byte[] out = data.getBytes(StandardCharsets.UTF_8);
 OutputStream stream = con.getOutputStream();
     stream.write(out);
 return con.getResponseCode();
`