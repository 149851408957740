import { url } from "../url/url";
export const Title = `A user orders list`;
export const Requires = `requires authentication`;
export const Content = `Get a list of orders that belongs to a user`;
export const Method = "GET";
export const Url = `api/user/{id}/orders`;
export const Bash =
    `curl --request GET 
    --get "${url}/api/user/officia/orders?search=corporis&orderby=expedita&arrange=quia&size=odio&page=eos&city=dolores&region=voluptas&delivery-type=accusantium&multiple-status[0]=1&date-range[0]=2021-02-01&date-range[1]=2021-02-08" 
    --header "Api-Key: {YOUR_AUTH_KEY}" 
    --header "Content-Type: application/json" 
    --header "Accept: application/json"`;

export const JavaScript = `
const url = new URL(
    "${url}/api/user/officia/orders"
);

const params = {
    "search": "corporis",
    "orderby": "expedita",
    "arrange": "quia",
    "size": "odio",
    "page": "eos",
    "city": "dolores",
    "region": "voluptas",
    "delivery-type": "accusantium",
    "multiple-status[0]": "1",
    "date-range[0]": "2021-02-01",
    "date-range[1]": "2021-02-08",
};
Object.keys(params)
    .forEach(key => url.searchParams.append(key, params[key]));

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `
{
    "current_page": 1,
     "data": [
          {
  "id": 1107985,
   "tracking": "DOT-djdhhA",
   "name": "name",
   "lastname": "last name",
    "adress": "the addresse of the client",
     "city": 253,
      "amount": 0,
        "free_shipping": "No",
         "phone": "0634073697",
         "hub": 1,
          "shipper": 1158,
           "created_at": "2022-01-04T15:11:27.000000Z",
            "updated_at": "2022-01-04T15:11:27.000000Z",
    "status": 4,
     "qc": "not passed",
      "payment_method": 1,
       "order_number": "CMD-48889410",
        "article": "extra information about the package \n",
         "size": "small",
          "delivery_type": "Forward",
           "code_rtc": "",
            "stock": "None",
   "delivery_operation": 2,
  "attempts_count": 1,
   "aging": 0,
    "order_city": "Salé",
   "order_status":"En-livraison",
    "order_region": "Tanger-Tétouan-Al Hoceïma",
    "order_company": "your shop name",
   "first_mile": "Salé",
    "last_mile": "Salé",
  "runsheet_package": {
     "id": 148596,
     "action_date": null,
      "rescheduled": null,
       "status":"En-livraison",
         "sub_status": []
     }
    }
  ],
  "first_page_url": "https://provider.tlog.ma/api/orders?page=1",
  "from": 1,
  "last_page": 171,
  "last_page_url": "https://provider.tlog.ma/api/orders?page=171",
  "links": [
   {
   "url": null,
   "label": "pagination.previous",
   "active": false
   },
   {
   "url": "https://provider.tlog.ma/api/orders?page=1",
   "label": "1",
   "active": true
    }
     {
      "url": "https://provider.tlog.ma/api/orders?page=2",
       "label": "pagination.next",
    "active": false
     }
  ],
  "next_page_url": "https://provider.tlog.ma/api/orders?page=2",
  "path": "https://provider.tlog.ma/api/orders",
   "per_page": 1,
    "prev_page_url": null,
   "to": 1,
    "total": 2
   }
`
export const URLParameters = [{ 'id': 'string' }];
export const QueryParameters = [
    {
        "id": 0,
        "name": "search",
        "ParamType": "string",
        "Description": "to search within the list",
        "Required": "no",

    },
    {
        "id": 1,
        "name": "orderby",
        "ParamType": "string",
        "Description": "to specify column to sort by",
        "Required": "no",

    },
    {
        "id": 2,
        "name": "arrange",
        "ParamType": "string",
        "Description": "to specify sorting arrange.",
        "Required": "no",
    },
    {
        "id": 3,
        "name": "size",
        "ParamType": "string",
        "Description": "'small' | 'medium' | 'extra' to filter by size",
        "Required": "no",
    },
    {
        "id": 4,
        "name": "city",
        "ParamType": "string",
        "Description": "to filter by city.",
        "Required": "no",
    }
    ,
    {
        "id": 5,
        "name": "page",
        "ParamType": "string",
        "Description": "to specify the page number",
        "Required": "no",
    }
    ,
    {
        "id": 6,
        "name": "region",
        "ParamType": "string",
        "Description": "region to filter by region.",
        "Required": "no",
    }
    ,
    {
        "id": 7,
        "name": "delivery-type",
        "ParamType": "string",
        "Description": "'Forward' | 'Reverse' to filter by delivery type.",
        "Required": "no",
    }
    ,
    {
        "id": 8,
        "name": "multiple-status",
        "ParamType": "string [ ] ",
        "Description": "to filter by statuses.",
        "Required": "no",
    }
    ,
    {
        "id": 9,
        "name": "date-range",
        "ParamType": "string [ ] ",
        "Description": "contains a start and an end date. eg : [start,end]",
        "Required": "no",
    }
]

export const CSharp = `
var client = new HttpClient();
    client.BaseAddress = new Uri("${url}");
    client.DefaultRequestHeaders.Accept.Clear();
    client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
    client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
    client.DefaultRequestHeaders.Add("Content-Type", "application/json");
Dictionary<string, string> params = new Dictionary<string, string>();
    params.Add("search","aut");
    params.Add("orderby","molestiae");
    params.Add("arrange","ut");
    params.Add("size","est");
    params.Add("page","quia");
    params.Add("city","dicta");
    params.Add("region","porro");
    params.Add("deliery-type","dolores");
    params.Add("multiple-status[0]","1");
    params.Add("date-range[0]","2021-02-01");
    params.Add("date-range[1]","2021-02-08");
string url="/api/user/officia/orders?";
foreach(var item in params)
    {    url+=item.Key+"="+item.Value+"&";    }  
HttpResponseMessage response = await client.GetAsync(url);
return response;
}
`
export const java = ` 
URL url = new URL("${url}/api/user/officia/orders?");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
con.setRequestMethod("POST");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
    con.setDoOutput(true);
Map<String, String> parameters = new HashMap<>();
    parameters.put("search","aut");
    parameters.put("orderby","molestiae");
    parameters.put("arrange","ut");
    parameters.put("size","est");
    parameters.put("page","quia");
    parameters.put("city","dicta");
    parameters.put("region","porro");
    parameters.put("delivery-type","dolores");
    parameters.put("multiple-status[0]","1");
    parameters.put("date-range[0]","2021-02-01"); 
    parameters.put("date-range[1]","2021-02-08"); 
StringBuilder result = new StringBuilder();
    for (Map.Entry<String, String> entry : parameters.entrySet()) {
          result.append(URLEncoder.encode(entry.getKey(), "UTF-8"));
          result.append("=");
          result.append(URLEncoder.encode(entry.getValue(), "UTF-8"));
          result.append("&");
    }    
String resultString = result.toString();
    resultString = resultString.length() > 0
          ? resultString.substring(0, resultString.length() - 1)
          : resultString;
DataOutputStream out = new DataOutputStream(con.getOutputStream());
    out.writeBytes(resultString);
    out.flush();
    out.close();      
return con.getResponseMessage();
`