import React from "react";

function URLTable({ data }) {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden ">
            <table className="min-w-fit bg-light border-2">
              <thead className="bg-light border-b-2">
                <tr>
                  <th
                    scope="col"
                    className="py-3 px-6 text-xs font-medium tracking-wider text-left border-r-2 text-white uppercase"
                  >
                    Param Name
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-6 text-xs font-medium tracking-wider text-left text-white uppercase"
                  >
                    Param Type
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-light font-medium">
                  <td className="py-4 px-6 text-sm border-r-2 text-white border-y-2 whitespace-nowra">
                    {Object.keys(data[0])}
                  </td>
                  <td className="py-4 px-6 text-sm  whitespace-nowrap border-y-2 text-white">
                    {Object.values(data[0])}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default URLTable;
