import React from "react";

function UpdatePickupTable() {
  return (
    <div className="flex flex-col -mt-2.5">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-fit bg-light border-2">
              <tbody>
                    <tr className="bg-light font-medium">
                      <td className="py-4 px-6 text-sm text-white border-y-2 whitespace-nowrap">
                        <span className="bg-light px-1 py-1 border-2 MyBorder">
                          pickup
                        </span>
                      </td>
                      <td className="py-4 px-4 text-sm  whitespace-nowrap border-y-2 text-white">
                        object
                      </td>
                      <td className="text-white border-2">
                        <table  className="min-w-fit bg-light">
                            <tbody>
                              
                                <tr className="bg-light font-medium ">
                                <td className="text-sm   whitespace-nowrap  text-white">
                                <table  className="min-w-fit bg-light">
                                <thead className="bg-light border-b-2">
                <tr>
                  <th
                    scope="col"
                    className="py-3 px-6 text-xs font-medium tracking-wider text-left text-white uppercase"
                  >
                    Param Name
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-3 text-xs font-medium tracking-wider text-left text-white uppercase"
                  >
                    Param Type
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-3 text-xs font-medium tracking-wider text-left text-white uppercase"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-3 text-xs font-medium tracking-wider text-left text-white uppercase"
                  >
                    Required
                  </th>
                </tr>
              </thead>
            <tbody className="bg-light">
                <tr>
                    <td className="py-4 px-3 text-sm  whitespace-nowrap  text-white">
                        <span className="bg-light px-1 py-1 MyBorder">
                          city
                        </span>
                    </td>
                    <td className="py-4 px-3 border-y-2">string</td>
                    <td className="py-4 px-3 border-y-2">pickup city (should match our records)</td>
                    <td className="py-4 px-3 border-y-2">no</td>

                </tr>
                <tr className="bg-light font-medium border-y-2">
                    <td className="py-4 px-3 text-sm   whitespace-nowrap  text-white">
                    <span className="bg-light px-1 py-1 border-2 MyBorder">
                          location
                        </span>
                    </td>
                    <td className="py-4 px-3 ">string</td>
                    <td className="py-4 px-3">pickup address</td>
                    <td className="py-4 px-3">no</td>                
                </tr>
                <tr className="bg-light font-medium border-0">
                    <td className="py-4 px-3 text-sm   whitespace-nowrap  text-white">
                    <span className="bg-light px-1 py-1 border-2 MyBorder">
                          phone
                    </span>
                    </td>
                    <td className="py-4 px-3">string</td>
                    <td className="py-4 px-3">phone must be a valid phone number</td>
                    <td className="py-4 px-3">no</td>                
                </tr>
            </tbody>
                        </table>
                                </td>
                                <td className="py-4 px-4 border-l-2">no</td>
                                </tr>
                            </tbody>
                        </table>
                      </td>
                      
                    </tr>
                 
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePickupTable;
