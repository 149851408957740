import { url } from "../url/url";
export const Title = `Revenue - Week revenue.`;
export const Requires = `requires authentication`;
export const Content = `get the total revenue of the week.`;
export const Method = "GET";
export const Url = `api/finance/weekrevenue`;
export const Bash =
    `curl --request GET 
          --get "${url}/api/finance/weekrevenue?user=94" 
          --header "Api-Key: {YOUR_AUTH_KEY}" 
          --header "Content-Type: application/json" 
          --header "Accept: application/json"       
 `;
export const JavaScript = `const url = new URL(
    "${url}/api/finance/weekrevenue?user=94"
);

const params = {
    "user": "94",
};
Object.keys(params)
    .forEach(key => url.searchParams.append(key, params[key]));

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());"
`;
export const Response = `

[
    {
        "cash": 0,
        "day": "Monday"
    },
    {
        "cash": 0,
        "day": "Tuesday"
    },
    {
        "cash": 0,
        "day": "Wednesday"
    },
    {
        "cash": 0,
        "day": "Thursday"
    },
    {
        "cash": 0,
        "day": "Friday"
    },
    {
        "cash": 0,
        "day": "Saturday"
    },
    {
        "cash": 0,
        "day": "Sunday"
    }
]
`;
export const QueryParameters = [
    {
        "id": 0,
        "name": "user",
        "ParamType": "string",
        "Description": "id of the user to get it's statistics.",
        "Required": "Yes",
    }
];
export const CSharp = `
var client = new HttpClient();
    client.BaseAddress = new Uri("${url}");
    client.DefaultRequestHeaders.Accept.Clear();
    client.DefaultRequestHeaders.Accept.Add(newMediaTypeWithQualityHeaderValue("application/json"));
    client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
    client.DefaultRequestHeaders.Add("Content-Type", "application/json");
Dictionary<string, string> params = new Dictionary<string, string>();
       params.Add("user","94");
string url="/api/finance/weekrevenue?";
foreach(var item in params)
    {    url+=item.Key+"="+item.Value+"&";    }  
HttpResponseMessage response = awaitclient.GetAsync(url);
return response;
`;

export const java = `
URL url = new URL("${url}/api/finance/weekrevenue?");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
con.setRequestMethod("POST");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
    con.setDoOutput(true);
Map<String, String> parameters = new HashMap<>();
    parameters.put("user", "94"); 
StringBuilder result = new StringBuilder();
for (Map.Entry<String, String> entry : parameters.entrySet()) {
          result.append(URLEncoder.encode(entry.getKey(), "UTF-8"));
          result.append("=");
          result.append(URLEncoder.encode(entry.getValue(), "UTF-8"));
          result.append("&");
    }    
String resultString = result.toString();
    resultString = resultString.length() > 0
          ? resultString.substring(0, resultString.length() - 1)
          : resultString;
DataOutputStream out = new DataOutputStream(con.getOutputStream());
    out.writeBytes(resultString);
    out.flush();
    out.close();
return con.getResponseMessage();`;