import React from "react";

function QueryTable({ QueryParameters }) {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-fit bg-light border-2">
              <thead className="bg-light border-b-2">
                <tr>
                  <th
                    scope="col"
                    className="py-3 px-6 text-xs font-medium tracking-wider text-left text-white uppercase"
                  >
                    Param Name
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-3 text-xs font-medium tracking-wider text-left text-white uppercase"
                  >
                    Param Type
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-3 text-xs font-medium tracking-wider text-left text-white uppercase"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-3 text-xs font-medium tracking-wider text-left text-white uppercase"
                  >
                    Required
                  </th>
                </tr>
              </thead>
              <tbody>
                {QueryParameters.map((data, id) => {
                  return (
                    <tr className="bg-light font-medium" key={id}>
                      <td className="py-4 px-6 text-sm text-white border-y-2 whitespace-nowrap">
                        <span className="bg-light px-1 py-1 border-2 MyBorder">
                          {data.name}
                        </span>
                      </td>
                      <td className="py-4 px-3 text-sm  whitespace-nowrap border-y-2 text-white">
                        {data.ParamType}
                      </td>
                      
                      <td className="py-4 px-3 text-sm text-white border-y-2 whitespace-nowrap ">
                        {data.Description}
                      </td>
                
                      <td className="py-4 px-3 text-sm text-white border-y-2 whitespace-nowrap ">
                        {data.Required}
                      </td>
                    </tr>

                  
                   
                  );
                })}
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QueryTable;
