import { url } from "../url/url";
export const Title = `Revenue - Revenue by size..`;
export const Requires = `requires authentication`;
export const Content = `Get the cash collected for orders grouped by size.`;
export const Method = "GET";
export const Url = `api/finance/revenuebysize`;
export const Bash = `curl --request GET 
    --get "${url}/api/finance/revenuebysize?user=94" 
    --header "Api-Key: {YOUR_AUTH_KEY}" 
    --header "Content-Type: application/json" 
    --header "Accept: application/json"    
 `;
export const JavaScript = `
const url = new URL(
    "${url}/api/finance/revenuebysize?user=94"
);

const params = {
    "user": "94",
};
Object.keys(params)
    .forEach(key => url.searchParams.append(key, params[key]));

const headers = {
    "Api-Key": "{YOUR_AUTH_KEY}",
    "Content-Type": "application/json",
    "Accept": "application/json",
};

fetch(url, {
    method: "GET",
    headers,
}).then(response => response.json());
`
export const Response = `
[
    {
        "amount": 12000,
        "count": 25,
        "month": "January"
    },
    {
        "amount": 23000,
        "count": 40,
        "month": "February"
    },
    {
        "amount": 26000,
        "count": 55,
        "month": "March"
    },
    {
        "amount": 40000,
        "count": 60,
        "month": "April"
    },
    {
        "amount": 43444,
        "count": 65,
        "month": "May"
    },
    {
        "amount": 50334,
        "count": 75,
        "month": "June"
    },
    {
        "amount": 53614,
        "count": 78,
        "month": "July"
    },
    {
        "amount": 15544,
        "count": 95,
        "month": "August"
    },
    {
        "amount": 35144,
        "count": 105,
        "month": "September"
    },
    {
        "amount": 545444,
        "count": 255,
        "month": "October"
    },
    {
        "amount": 653344,
        "count": 400,
        "month": "November"
    },
    {
        "amount": 844222,
        "count": 565,
        "month": "December"
    }
]
`
export const QueryParameters = [
    {
        "id": 0,
        "name": "user",
        "ParamType": "string",
        "Description": "id of the user to get it's statistics.",
        "Required": "Yes",
    }
];
export const CSharp = `
var client = new HttpClient();
    client.BaseAddress = new Uri("${url}");
    client.DefaultRequestHeaders.Accept.Clear();
    client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue("application/json"));
    client.DefaultRequestHeaders.Add("Api-Key", "{YOUR_AUTH_KEY}");
    client.DefaultRequestHeaders.Add("Content-Type", "application/json");
Dictionary<string, string> params = new Dictionary<string, string>();
        params.Add("user","94");
string url="/api/finance/revenuebysize?";
    foreach(var item in params)
    {    url+=item.Key+"="+item.Value+"&";    }  
HttpResponseMessage response = await client.GetAsync(url);
return response;
`

export const java = `
URL url = new URL("${url}/api/finance/revenuebysize?");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
con.setRequestMethod("POST");
    con.setRequestProperty("Content-Type", "application/json");
    con.setRequestProperty("Api-Key", "{YOUR_AUTH_KEY}");
    con.setRequestProperty("Accept", "application/json");
    con.setDoOutput(true);
Map<String, String> parameters = new HashMap<>();
    parameters.put("user", "94"); 
StringBuilder result = new StringBuilder();
for (Map.Entry<String, String> entry : parameters.entrySet()) {
          result.append(URLEncoder.encode(entry.getKey(), "UTF-8"));
          result.append("=");
          result.append(URLEncoder.encode(entry.getValue(), "UTF-8"));
          result.append("&");
    }    
String resultString = result.toString();
    resultString = resultString.length() > 0
          ? resultString.substring(0, resultString.length() - 1)
          : resultString;
DataOutputStream out = new DataOutputStream(con.getOutputStream());
    out.writeBytes(resultString);
    out.flush();
    out.close();      
return con.getResponseMessage();`;